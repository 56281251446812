import React from 'react'
import { graphql } from 'gatsby'
import { useLocation } from '@reach/router'

import IndexLayout from '@/layouts/index'
import { Pagination } from '@/components/atoms'
import { SEO } from '@/components/molecules'
import { PostCardList, CategoryRelatedBoard } from '@/components/organisms'
import { SearchConnect } from '@/components/connects'
import {
  CategoryPageContext,
  CategorySubCategory,
} from '@gatsby-node/createCategoryPages'
import { MarkdownRemarkConnection } from '@types/graphql-types'
import categoryData from '@content/config/category.yml'
import { humanize, machinize } from '@/utils'
import AppConfig from '@/config/app'

import * as styles from './category.module.scss'

type Props = {
  pageContext: CategoryPageContext
  data: {
    allMarkdownRemark: MarkdownRemarkConnection
    thumbnail: any
  }
}

const makeSubcategories = (data: CategorySubCategory[], category: string) => {
  const filtered = data.filter((item: CategorySubCategory) => {
    return category === item.category
  })
  return filtered[0].subcategory
}

const makeBreadcrumbItems = (pathname: string) => {
  const [category] = pathname.split('/').slice(2)
  const items = [
    {
      name: 'ホーム',
      link: '/',
      icon: 'home',
    },
    {
      name: humanize(category),
      link: `/category/${category}`,
    },
  ]
  return items
}

const Category: React.FC<Props> = ({ pageContext, data }) => {
  const { category: categoryStr } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark

  const location = useLocation()
  const pathname = location.pathname
  const breadcrumbs = makeBreadcrumbItems(pathname)

  const ftitle = humanize(categoryStr)
  const mcategory = machinize(categoryStr)
  const subcategories = makeSubcategories(categoryData, categoryStr)

  const paginationContext = { ...pageContext, pathname }
  const thumbnailPath = data.thumbnail.childImageSharp.resize.src
  const { blog } = AppConfig(location)

  const category = {
    title: `カテゴリー「${categoryStr}」の記事一覧`,
    description: `${blog.title}の「${categoryStr}」についての記事一覧です。`,
    url: location.href
  }

  return (
    <IndexLayout>
      <SEO type={"category"} pageType={"category"} category={category} thumbnailPath={thumbnailPath} />
      <SearchConnect>
        <CategoryRelatedBoard
          className={styles.category_related_board}
          title={ftitle}
          totalCount={totalCount}
          category={mcategory}
          subcategories={subcategories}
          breadcrumbs={breadcrumbs}
        />
        <PostCardList edges={edges} />
        <Pagination context={paginationContext} />
      </SearchConnect>
    </IndexLayout>
  )
}

export const CategoryTemplateQuery = graphql`
  query($category: String, $skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___createdOn], order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
      skip: $skip
      limit: $limit
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tags
            createdOn
            updatedOn
            image {
              publicURL
              childImageSharp {
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
          excerpt
        }
      }
    }
    thumbnail: file(relativePath: { eq: "blog-ogp-1200x630.png"}) {
      childImageSharp {
        resize(width: 1200, quality: 90) {
          src
        }
      }
    }
  }
`

export default Category
